@tailwind base;
@tailwind components;
@tailwind utilities;

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #eeeeee transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #eeeeee;
  border-radius: 24px;
  border: 0px solid transparent;
}
